import { hasData } from "./hasData"

export function getProfileUrl(base,user_name,navtype,page,querystring) {

    var vnavtype = `/${navtype}`
    var vquerystring = ``

    //EXCEPTIONS

    //remove navtype from homepage
    if (['profile','posts'].indexOf(navtype) !== -1) {
      vnavtype = ""
    }

    //paginations / sorting page types
    if (['followers','following'].indexOf(navtype) !== -1) {  //diff appraoch for followers
        if (validPage(page) > 1)
        {
          vquerystring = `/${page}` //?${querystring}`
        }
    } else if (['chathistory'].indexOf(navtype) !== -1) {  //diff appraoch for chathistory
      vnavtype = "/chat/history"
      if (validPage(page) > 1)
      {
        vquerystring = `/${page}` //?${querystring}`
      }
    } else if (['showreel'].indexOf(navtype) !== -1) { //diff appraoch for showreels
        if (validPage(page) > 1)
        {
          vquerystring = `?page=${page}` //&${querystring}`
        }
    }

    //RETURN
    return `${base}/${user_name?.toLowerCase()}${vnavtype}${vquerystring}`

  }

function validPage(n) {
  if (!hasData(n)) return 1;
  return parseInt(n);
}