import Link from "next/link";
import SvgJsx from "../templateux/svg/svg-jsx";

export default function HeaderRow({
    headertitle
    ,action_condition
    ,action
    ,action_align='left'
}) {
    return (<>
        <div className="flex items-center content-center w-full ">
            <div className={`${action_align=='left' ? 'flex-0' : 'flex-1'} `}>
                <h2 
                    className={`font-extrabold text-lg sm:text-2xl
                                text-white
                                `}
                >
                    {headertitle}
                </h2>
            </div>
            {action_condition &&
            <div
                className="flex-0 flex items-center content-center mx-4 "
            >
                {action}
            </div>
            }
        </div>
    </>)
}