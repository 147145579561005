import { meta } from "@/lib/config"
import Link from "next/link"
import Footer from "../templateux/footer"
import LayoutPageBlank from "../templateux/layout/layout-page-blank"
import NavPrimary from "../templateux/nav/nav-primary"
import ProfileSingleHeader from "./item/profile-single-header"



function ProfileMissing({
     user_name
    ,userdata
    ,isUserDataLoading
    ,isUserDataError
    ,navtype
    ,helptype
    ,ssr_data_tribe
}) {

    return <>
                {/* MAIN CONTAINER */}
                 <LayoutPageBlank
                    title={`${user_name} | No such user! | ${navtype}`}
                    url={`${ssr_data_tribe?.server_website}/${user_name}`}
                    image={meta(ssr_data_tribe).image}
                    description={`${user_name}'s ${navtype} page on ${ssr_data_tribe?.brand_anme} | ${meta(ssr_data_tribe).description}`}
                    keywords={`${user_name},${meta(ssr_data_tribe).keywords}`}
                    ssr_data_tribe={ssr_data_tribe}
                >
                  
                    <NavPrimary
                      user_name={user_name}
                      userdata={userdata}
                      isUserDataLoading={isUserDataLoading}
                      isUserDataError={isUserDataError}
                      navtype={navtype}
                      ssr_data_tribe={ssr_data_tribe}
                      />

                      {/* HEADER */}
                      <ProfileSingleHeader
                        user_name={user_name}
                        userdata={{}}
                        showReminder={false}
                        set_showReminder={()=>{}}
                        profiledata_active={{}}
                        set_profiledata_active={()=>{}}
                        stream_url_active={''}
                        player_state={{}}
                        set_player_state={()=>{}}
                        owner_block_json={[]}
                        owner_staff_json={[]}
                        owner_bol_admin_help={0}
                        owner_bol_staff_help={0}
                        owner_id={0}
                        navtype={navtype}
                        helptype={helptype}
                      />






                              {/* MENUBAR */}
                      {/* <ProfileMenu
                          user_name={user_name}
                          navtype={navtype}
                          owner_id={0}
                          userdata={{}}
                          profiledata_active={{}}
                      /> */}
                    
                        <div className=" flex text-center items-center contents-center justify-center my-20 mx-auto">
                          <div>
                            <div className=" text-white">
                                <h1 className="font-bold text-2xl">
                                  No such user!
                                </h1>
                              </div>
                              <div className=" text-white">
                                <span className="block ">Hopefully you find what you are looking for...</span>
                              </div>
                              <div className='mt-5'>
                                <Link
                                  href='/directory?page=1&amp;sort=followers&amp;dir=down'
                                  className='underline hover:no-underline 
                                               text-blue-500 hover:text-white 
                                              '>
                                  
                                    View the members directory
                                  
                                </Link>
                              </div>
                              <div className='mt-5'>
                                <Link
                                  href='/'
                                  className='underline hover:no-underline 
                                              text-blue-500 hover:text-white 
                                              '>
                                  Return to{ssr_data_tribe?.brand_name}

                                </Link>
                              </div>

                          </div>
                        </div>
                  
                    <Footer />
                </LayoutPageBlank>
                </>;
}

export default ProfileMissing