import { hasData } from "./hasData"
import { textProcess } from "./textprocess"
import { uniqueMembers } from "./uniqueMembers"

export function getChatHeadline(profiledata,members) {

    let chatters_total = hasData(members) 
                        ? uniqueMembers(members)?.length
                        : 0

    let listeners_total = hasData(profiledata?.current_listeners) 
                        ? profiledata?.current_listeners 
                        : 0

    return textProcess(profiledata?.stream_status?.toString()=="1"
                ? `${listeners_total} Listening + ${chatters_total} Chatting`
                : `${chatters_total} Chatting`)
  }