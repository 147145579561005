
import * as React from "react";

import { chatmenu, title_format } from "@/lib/config";
import { Fragment } from "react";
import { updateTitle } from "@/lib/utils/updateTitle";
import { textProcess } from "@/lib/utils/textprocess";

export default function ChatList({
    filter
   ,set_filter
   ,set_extra
   ,submenuarray
   ,className
   ,isAdmin
   ,isUser
   ,report_queue
   ,user_name
   ,set_chatdata
   ,navtitle
   ,ssr_data_tribe
   ,permaurl
}) {

  var menukeys = Object.keys(submenuarray);
                     
                  
  return (<>


                        <div className="menu flex flex-1 flex-col gap-y-1  p-1
                                      bg-gray-800 "
                        >

                          <div className=" overflow-hidden truncate text-sm ">


                          {/* ARRAY */}
                          {menukeys.map((e,index) => 
                          <Fragment key={index}>
                           {(submenuarray[e].public==1 //is guest accessing public chat
                            || ((submenuarray[e].public==0 && isUser) //is user accessing member chat
                                && (submenuarray[e].admin==0 //chatroom is not for admins
                                    || (submenuarray[e].admin==1 && isAdmin)))) && //chat for admins, user is an admin
                           <div className={`px-2 py-1 rounded-md  mt-1 cursor-pointer
                              
                              ${chatmenu.menuarray[filter]?.short == submenuarray[e].short 
                                ? "bg-gray-600 font-bold" 
                                : ""}
                                flex items-center content-center
                              `}>


                              
                              <div 
                              
                              className="flex-1"
                              
                              onClick={()=>{
                               
                                set_filter(e);
                                set_extra(e,user_name);
                                set_chatdata([]);
                                updateTitle(title_format(`${navtitle} | ${submenuarray[e].title}`,ssr_data_tribe));
                                window.history.pushState("object or string", title_format(`${navtitle} | ${submenuarray[e].title}`,ssr_data_tribe),`${permaurl}${e=="all" ? "" : `?filter=${e}`}`);
                                updateTitle(title_format(`${navtitle} | ${submenuarray[e].title}`,ssr_data_tribe));
                              }}
                              
                              >{textProcess(submenuarray[e].title)}</div>
                              {isAdmin && 
                              report_queue > 0 &&
                              submenuarray[e].short == "Reported" &&
                              <div className="flex-0  ml-2">
                                <span className="flex h-3 w-3 relative">
                                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                                
                              </div>
                              }
                            
                            
                            
                         </div>
                          }
                          </Fragment>
                          )}


                          </div>
                          

                        </div>
   


  </>)
}