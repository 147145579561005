
import SvgJsx from "../templateux/svg/svg-jsx";
import ProfileSection from "./profile-section";
import ScheduleArrayHome from "./schedule-home/schedule-array-home";
import SubHeader from "./subheader";
import Link from "next/link";


export default function ProfileScheduleSidebar({
    profiledata
    ,scheduledata
    , isAdmin
    ,userdata
    ,cache
    ,set_cache
}) {


        return (<>
                <div
                className="w-full"
                >
                 
                <ProfileSection>
                    
                    <SubHeader
                        action_url={`/${profiledata?.user_name}/settings/content?panel=userschedule#userschedule`}
                        action_text={`edit`}
                        action_condition={isAdmin}
                        headertitle={'Schedule'}
                    />
                    
                    <div className={`text-sm`}>
                    <ScheduleArrayHome
                        itemdata={scheduledata}
                        itemmeta={null}
                        isLoading={false} //{schedulearraydataisLoading}
                        isError={false} //{schedulearraydataisError}
                        sum_items={0}
                        mutateItemsDeleteOne={()=>{}}
                        userdata={userdata}
                        ownerdata={profiledata}
                        details_collapsed={true}
                        display_view={"miniprofile"}
                        cache={cache}
                        set_cache={set_cache} 
                        slugfilter={null}
                        show_adminbar={false}
                        nodata={"Nothing to show"}
                        navtype={'schedule'}
                        helptype={'content'}
                        isAdmin={false}
                    />
                    </div>
                </ProfileSection>


                
                </div>
            </>)
}