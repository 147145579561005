
import { openWindow } from "@/lib/utils/openWindow";
import SvgJsx from "../templateux/svg/svg-jsx";
import ProfileSection from "./profile-section";
import SubHeader from "./subheader";
import Link from "next/link";
import { textProcess } from "@/lib/utils/textprocess";


export default function ProfileRSS({
    profiledata
    , isAdmin
    , set_isOpen
}) {


        return (<>
                <div
                className="w-full"
                >
                 {profiledata?.bol_podcast_rss?.toString() == "1" && 
                <ProfileSection>
                    
                    <SubHeader
                        action_url={`/${profiledata?.user_name}/settings/content?panel=podcast#podcast`}
                        action_text={`edit`}
                        action_condition={isAdmin}
                        headertitle={'RSS Links'}
                    />
                    
                    <div className={`text-sm`}>
                       <ul className=" mt-2 flex flex-col gap-y-1">
                            <li className="list-none">
                                <a href={`/${profiledata?.user_name}/audio/podcast.rss`}
                                    target={`_blank`}
                                    className={`w-full max-w-sm flex flex-0 items-center content-center p-1 border border-transparent rounded-md
                                    text-blue-400 hover:text-blue-100 hover:border-gray-700`}>
                                        <SvgJsx 
                                            type={'fill'}
                                                
                                            icon={'rss-sm'}
                                            className={`h-4 w-4 mr-2`}
                                            title={textProcess(`Audio RSS (for podcasts)`)}
                                        />
                                        <span>{textProcess(`Audio RSS (for podcasts)`)}</span>
                                    </a>
                            </li>
                            
                            <li className="list-none">
                                <a 
                                    href={`/${profiledata?.user_name}/posts/podcast.rss`}
                                    target={`_blank`}
                                    className={`w-full max-w-sm flex flex-0 items-center content-center p-1 border border-transparent rounded-md
                                   text-blue-400 hover:text-blue-100 hover:border-gray-700`}>
                                        <SvgJsx 
                                            type={'fill'}
                                                
                                            icon={'rss-sm'}
                                            className={`h-4 w-4 mr-2`}
                                            title={textProcess(`Posts RSS`)}
                                        />
                                        <span>{textProcess(`Posts RSS`)}</span>
                                    </a>
                            </li>
                            <li className="list-none">
                                <div 
                                    onClick={()=>{
                                        if (typeof window !== undefined) {
                                            set_isOpen('embed')
                                            //openWindow(`/embed/${profiledata?.user_name}?tab=embed`, `Embeddable Player for ${profiledata?.user_name}`, window, 580, 380);
                                          }
                                    }}
                                    className={`cursor-pointer w-full max-w-sm flex flex-0 items-center content-center p-1 border border-transparent rounded-md
                                   text-blue-400 hover:text-blue-100 hover:border-gray-700`}>
                                        <SvgJsx 
                                            type={'fill'}
                                                
                                            icon={'code-sm'}
                                            className={`h-4 w-4 mr-2`}
                                            title={textProcess(`Embeddable Player`)}
                                        />
                                        <span>{textProcess(`Embeddable Player`)}</span>
                                    </div>
                            </li>
                       </ul>
                    </div>
                </ProfileSection>
                }

                
                </div>
            </>)
}