
import { useContext, useEffect, useRef, useState } from "react"
import ProfileLinks from "../profile-links"
import ShowAlertBanner from "../show-alert-banner"
import isAdminUser from '@/lib/utils/isAdminUser'
import { PlayerContext } from "@/components/player/player-wrapper"
import ProfileRSS from "../profile-rss"
import ProfileMenuTipJarNew from "./profile-menu-tip-jar-NEW"
import HeaderBar from "@/components/templateux/header-bar/header-bar"
import Tabs from "@/components/templateux/tabs"
import { config, tabarray_follow_details, tabarray_posts_filter } from "@/lib/config"
import ProfileMenuTipJar from "./profile-menu-tip-jar"
import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { ModalAbout } from "@/components/templateux/modal/modal-about"
import { ModalChat } from "@/components/templateux/modal/modal-chat"
import ProfileChatDynamic from "@/components/ably/ProfileChatDynamic"
import { useRouter } from "next/router"
import { useConfirm } from "@/lib/utils/hooks/useConfirm"
import { ProfileDescription } from "../profile-description"
import { getCacheDate } from "@/lib/utils/getCacheDate"
import ProfileScheduleSidebar from "../profile-schedule-sidebar"
import { hasData } from "@/lib/utils/hasData"
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing"
import { TribeContext } from "@/components/context/tribe-wrapper"
import { validTipJar } from "@/lib/utils/validTipJar"
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl"
import AvatarFlair from "@/components/templateux/user/avatar-flair"
import Link from "next/link"
import AvatarFlairClean from "@/components/templateux/user/avatar-flair-clean"
import { textProcess } from "@/lib/utils/textprocess"


function ProfileContainer({
     navtype
    ,subnavtype
    ,profiledata_active
    ,scheduledata_active
    ,owner_id
    ,userdata
    ,broadcast_id
    ,broadcastcache
    ,set_broadcastcache
    ,children
    ,isAdmin
    ,openModal
    ,showReminder
    ,set_showReminder
    ,isOpen
    ,set_isOpen
    ,display_view
    ,set_display_view
    ,isHeaderOnScreen
}) {


  
  const router = useRouter()
  const {player_state,set_player_state} = useContext(PlayerContext)
  const [show_users,set_show_users] = useState(false)
  const [show_chats,set_show_chats] = useState(false)
  const [isMounted,set_isMounted] = useState(false)
  const [isSafari,set_isSafari] = useState(true);
  
 const [cache, set_cache] = useState(getCacheDate())
 const ssr_data_tribe = useContext(TribeContext)


  useEffect(()=>{
    set_isMounted(true);
    if (typeof window !== "undefined") {

      set_show_chats((window?.innerWidth >= 1024 && (localStorage?.getItem(`chat-chatlists`) == "1" || !hasData(localStorage?.getItem(`chat-chatlists`)))));
      set_show_users((window?.innerWidth >= 1024 && (localStorage?.getItem(`chat-users`) == "1"     || !hasData(localStorage?.getItem(`chat-chatlists`)))));
      
      if (localStorage?.getItem(`chat-chatlists`) == "0") set_show_chats(false); //exception for bigger screens who closed the panels
      if (localStorage?.getItem(`chat-users`) == "0") set_show_users(false); //exception for bigger screens who closed the panels

      let bol_isSafari = /^((?!chrome|android).)*safari/i.test(window?.navigator?.userAgent);
      set_isSafari(bol_isSafari);
    }
  },[])
  
  const { isConfirmed } = useConfirm();

  useEffect(()=>{
    set_isOpen(openModal)
  },[
    openModal
  ])


        //Admin Lookup
        const [isAdmin_profile,set_isAdmin_profile] = useState(false)
        const [isAdmin_broadcast,set_isAdmin_broadcast] = useState(false)

    
        const closeModal =  async ()=>{
          if (isOpen == "chat") {
            const confirmed = await isConfirmed('Are you sure you want to close chat?');
            if (confirmed) set_isOpen('');
          } else {
            set_isOpen('');
          }
        }

        useEffect(()=>{
          set_isAdmin_profile(isAdminUser( profiledata_active?.id            //user_id
                                  ,profiledata_active?.bol_staff_help    //bol_staff_help
                                  ,profiledata_active?.staff_json        //staff_json
                                  ,profiledata_active?.bol_admin_help    //bol_admin_help
                                  ,userdata?.id?.toString()            //my_id
                                  ,userdata?.bol_admin?.toString()    //global_admin
                                  ,"profile"
                                  ))
          set_isAdmin_broadcast(isAdminUser( profiledata_active?.id            //user_id
                                  ,profiledata_active?.bol_staff_help    //bol_staff_help
                                  ,profiledata_active?.staff_json        //staff_json
                                  ,profiledata_active?.bol_admin_help    //bol_admin_help
                                  ,userdata?.id?.toString()            //my_id
                                  ,userdata?.bol_admin?.toString()    //global_admin
                                  ,"broadcast"
                                  ))
        },[
           profiledata_active
          ,userdata
        ])


    //LISTENER DATA
    // const { 
    //     data: broadcastlistenerstatsdata
    //   , loading: broadcastlistenerstatsdataisLoading
    //   , error: broadcastlistenerstatsdataisError
    //                   } = useSWRWrapper(
    //                     broadcast_id
    //                     ,`/api/public/v0/broadcast/stats?id=${broadcast_id}&cache=${broadcastcache}` //key
    //                     ,true //retry?
    //                     ,config.max_retries //max number of retries
    //                     ,config.retry_interval //retry interval
    //                     ,config.profile.stats_interval //refreshinterval (0=none)
    //                   )

    // useEffect(()=>{

    //   if (!broadcastlistenerstatsdataisLoading && !broadcastlistenerstatsdataisError && broadcastlistenerstatsdata) {
    //     set_player_state((prev) => {
    //       return {...prev
    //       ,current_listeners:broadcastlistenerstatsdata?.current_listeners || 0
    //       ,current_chatters:broadcastlistenerstatsdata?.current_chatters || 0
    //       ,sum_listeners:broadcastlistenerstatsdata?.sum_listeners || 0
    //       ,sum_members:broadcastlistenerstatsdata?.sum_members || 0
    //       ,sum_chatters:broadcastlistenerstatsdata?.sum_chatters || 0
    //       ,sum_emojis:broadcastlistenerstatsdata?.sum_emojis || 0
    //       ,sum_chats:broadcastlistenerstatsdata?.sum_chats || 0
    //       ,broadcast_id:broadcastlistenerstatsdata?.broadcast_id || 0
    //       ,start_date:broadcastlistenerstatsdata?.start_date || ''
    //       ,broadcast_title:broadcastlistenerstatsdata?.broadcast_title || ''
    //       ,broadcast_description:broadcastlistenerstatsdata?.broadcast_description || ''
    //     }}
    //     )
    //   }

    // },[
    //    broadcastlistenerstatsdata
    //   ,broadcastlistenerstatsdataisLoading
    //   ,broadcastlistenerstatsdataisError
    //   ,set_player_state
    // ])

    const set_tab_header = (n,url) => {
      set_display_view(n);
      router.push(url, undefined, { shallow: true });
    }

    const about = (<>
                            {validTipJar(profiledata_active) &&
                            <div className="flex-0 visible md:hidden"> 
                              <ProfileMenuTipJar
                                profiledata={profiledata_active}
                              />
                            </div>
                            }
                            <div 
                              onClick={()=>set_isOpen('about')}
                              className="ml-2 flex md:hidden text-gray-500 border-gray-700 hover:border-gray-400 border rounded-md cursor-pointer">
                                        <SvgJsx 
                                            type={ `fill` } 
                                            icon={`information-circle-sm` } 
                                            className={` w-7 h-7 p-1 flex-0 my-auto  mx-auto`}
                                            title={'About'}
                                        />
                            </div>
                      </>)

    const avatar = () => (<div className="mr-3">
                      <Link href={`/${profiledata_active?.user_name?.toLowerCase()}`}>

                        <AvatarFlairClean                  
                              isPlaying={false}
                              isContributor={profiledata_active?.contributor_level > 0}
                              isBot={false}
                              avatar_url={profiledata_active?.avatar_url}
                              avatar_url_sfw={profiledata_active?.avatar_url_sfw}
                              bol_nsfw={profiledata_active?.bol_nsfw}
                              avatar_crdate={profiledata_active?.avatar_crdate}
                              id={profiledata_active?.id}
                              user_name={profiledata_active?.user_name}
                              bgClass='w-8 h-8'
                                    containerClass='w-8 h-7 '
                                    avatarClass='w-8 h-8 border-2 border-transparent '
                                    rotaryContainerClass='w-8 h-8 mr-4'
                                    rotaryInternalClass='w-8 h-9'
                                    sizes={{
                                      width:36
                                      ,height:36
                                      ,quality:50
                                    }}
                          />

                      </Link>
                  </div>)

    return (<>

        {/* CONTAINER */}
        <div className="">

          {/* MAIN CONTENT */}
          <div className="flex-1">
          

          <div className="flex flex-row items-top max-w-7xl mx-auto bg-gray-900
           border-x border-gray-700">

            


            {/* MAIN CONTENT */}
            <div className="flex-1">


              {navtype!=='chat' && profiledata_active?.stream_status == "1" 
                  && (player_state?.content_id?.toString() == profiledata_active?.broadcast_id?.toString() && player_state?.content_table?.toString() == "broadcast")
                  &&
                  <ShowAlertBanner 
                    user_name={profiledata_active?.user_name}
                  />
                }

                
              

      {["posts","audio","soundboards","chat","settings"]?.indexOf(display_view) > -1 &&
      <div className={` ${["chat","replay"]?.indexOf(isOpen) > -1 ? "" : "sticky top-[46px] z-100" }`}>
        
        <HeaderBar
          headertitle={
            <div className="flex items-center content-center">
              <div>{isHeaderOnScreen  ? <></>  : <>{avatar()}</>}</div>
              <div className="flex-1">
                <Tabs
                tabarray={tabarray_posts_filter(profiledata_active?.user_name,profiledata_active,isAdmin)}
                clickval={display_view}
                set_clickval={set_tab_header}
                tab_style="selectboxhome"
              />
            </div>
            
          </div>
          
        }
          show_rss={false}
          rss_link={``}
          rss_text={''}
          action_button={about}
          show_action_button={true}
        />
        </div>
        }

        {["followers","following"]?.indexOf(display_view) > -1 &&
        <div className="sticky top-[46px] z-100">

        <HeaderBar
          headertitle={<div className="flex items-center content-center">
                          <div>{isHeaderOnScreen  ? <></>  : <>{avatar()}</>}</div>
                          <div className="flex-1">
          
                            <Tabs
                              tabarray={tabarray_follow_details(profiledata_active?.user_name, 
                                                                profiledata_active?.followers, 
                                                                profiledata_active?.following)}
                              clickval={display_view}
                              set_clickval={set_display_view}
                              tab_style="linkpill"
                            />
                        </div>
                      </div>}
          show_rss={false}
          rss_link={``}
          rss_text={''}
          action_button={about}
          show_action_button={true}
        />

        </div>
        }

       

                {children}

            </div>

             {/* SIDEBAR */}
            {["chat","replay"].indexOf(isOpen) == -1 && 
            <div className="  bg-gray-900 hidden md:block border-l  border-gray-700 ">
            {validTipJar(profiledata_active) &&
            <div
                className="hidden md:flex items-center content-center border-b h-16 sticky top-[46px] z-100 border-gray-700  
                 bg-gray-800
                            
                            "
            >
              
              <div className="flex-1 justify-center w-full flex ">
              
                
                  <ProfileMenuTipJarNew
                    profiledata={profiledata_active}
                  />
                
               
              </div>
              
            </div>
             }

            <div className="flex-0 md:w-64 
                            "
            >
            {/* USED TO MAKE THE RIGHT SIDE BORDER */}
            
            <div
                className="border-b pr-5
                md:border-gray-700
                            border-transparent 
                            "
            >


            

        

            {/* <div className="p-4">
              <ProfileSchedule
                profiledata={profiledata_active}
                isAdmin={isAdmin_profile}
              />
            </div> */}
         

            <div className="p-4">

              <ProfileRSS
                profiledata={profiledata_active}
                isAdmin={isAdmin_profile}
                set_isOpen={set_isOpen}
              />
            </div>

            {profiledata_active?.bol_description?.toString() == "1" &&
            <div className="p-4">
              <ProfileDescription
                profiledata={profiledata_active}
                isAdmin={isAdmin_profile}
              />
            </div>
          }

          {scheduledata_active?.length > 0 &&
          <div className="p-4 pt-0">

              <ProfileScheduleSidebar
                profiledata={profiledata_active}
                scheduledata={scheduledata_active}
                isAdmin={isAdmin_profile}
                userdata={userdata}
                cache={cache}
                set_cache={set_cache}
              />
            </div>
            }


            <div className="p-4 pt-0">

              <ProfileLinks 
                profiledata={profiledata_active}
                isAdmin={isAdmin_profile}
              />
            </div>

            </div>
            
            </div>



            <div
                className="  hidden md:flex h-16 px-5
                           
                            sticky top-10
                            "
            >

              <div className="flex items-center content-center flex-1 justify-center ">
              <div className="flex justify-center my-4">
                                    <div
                                        onClick={() => {
                                          window.scrollTo({top: 0, behavior: 'smooth'});
                                          // document.body.scrollTop = 0; // For Safari
                                          // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                        }}
                                        className="cursor-pointer bg-gray-600 hover:bg-gray-500 text-white font-bold text-sm italic px-4 py-1  rounded-full"
                                    >
                                    {textProcess(`Back to Top`)}
                                    </div>
                                </div>
              </div>
            </div>



            </div>
           }


          </div>
           
          </div>

          


        </div>
        <ModalAbout
          isOpen={isOpen=='embed'}
          closeModal={closeModal}
          profiledata={profiledata_active}
          ModalContent={<>
              <iframe 
                  width="100%" 
                  height="230" 
                  src={`/embed/${profiledata_active?.user_name}?tab=embed`} 
                  frameBorder="0" 
                  allow=""  
              />
          </>}
        />
        <ModalAbout
          isOpen={isOpen=='about'}
          closeModal={closeModal}
          profiledata={profiledata_active}
          ModalContent={<>
          <div>
            {profiledata_active?.bol_description?.toString() == "1" &&
              <div className="p-4">
                <ProfileDescription
                  profiledata={profiledata_active}
                  isAdmin={isAdmin}
                />
              </div>
            }

              <div className="p-4">

                <ProfileRSS
                  profiledata={profiledata_active}
                  isAdmin={isAdmin}
                  set_isOpen={set_isOpen}
                />
              </div>
              
              <div className="p-4 pt-0">

                <ProfileLinks 
                  profiledata={profiledata_active}
                  isAdmin={isAdmin}
                />
              </div>
            </div>
          </>}
        />

      <ModalChat
          isOpen={["chat"].indexOf(isOpen) > -1 && isMounted}
          ModalContent={<>
                    <ProfileChatDynamic
                      channel_id_chat_public={`${ssr_data_tribe?.brand_id}-${config.websockets.chat.public}${profiledata_active?.id}`}
                      channel_id_chat_private={`${ssr_data_tribe?.brand_id}-${config.websockets.chat.private}${userdata?.id}`}
                      channel_id_listen={`${ssr_data_tribe?.brand_id}-${config.websockets.listen}${profiledata_active?.id}`}
                      profiledata={profiledata_active}
                      userdata={userdata}
                      navtype={'chat'}
                      helptype={'chat'}
                      nodata={<LoadingProcessing />}
                      //ssr_data_chat={ssr_data_chat}
                      router={router}
                      show_users={show_users}
                      set_show_users={set_show_users}
                      show_chats={show_chats}
                      set_show_chats={set_show_chats}
                      closeModal={closeModal}
                      showReminder={showReminder}
                      set_showReminder={set_showReminder}
                      isSafari={isSafari}
                    />
            
          </>}
        />
    
    </>)
}

export default ProfileContainer