import PopupMenuOption from "@/components/templateux/popup-menu-option"
import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { config } from "@/lib/config"
import { getInterval } from "@/lib/utils/getInterval"
import { hasData } from "@/lib/utils/hasData"
import { IsJsonValid } from "@/lib/utils/isJsonValid"
import Link from "next/link"
import { Fragment, useEffect, useState } from "react"
import Popup from "reactjs-popup"

function ProfileMenuTipJarMobile({
  profiledata,
  preview=false,
  active=false
}) {

  const [open,set_open] = useState(false)
  const [isMounted,set_isMounted] = useState(false)

  useEffect(() => {
    set_isMounted(true)
  }, [])

    const [_tip_jar_json, set_tip_jar_json ] = useState(IsJsonValid(profiledata?.tip_jar_json) ? JSON.parse(profiledata?.tip_jar_json) : {items: []})

    const PopupTrigger = <div className="flex-0 flex items-center justify-center group cursor-pointer">

                    
                    
                        <div
                          className={` mr-1 flex cursor-pointer text-xs font-bold p-1.5 rounded-md  items-center content-center
                                     text-white border group-hover:bg-red-500 group-hover:border-white
                                     ${active 
                                      ? 'bg-red-600 border-red-700'
                                      : 'bg-red-600 border-gray-700'}
                                    ${getInterval(profiledata?.stream_end_date,profiledata?.timestamp) < config.tip_jar.shake_interval 
                                      && getInterval(profiledata?.stream_end_date,profiledata?.timestamp) !== -1 
                                      ? ` animate-notice mr-2 `
                                      : ``
                                    }
                                    `}
                                    >
                            <div 
                            role="button" 
                            className=" flex items-center content-center flex-1 truncate whitespace-nowrap overflow-hidden h-5 max-w-[220px]" 
                            
                            title={(parseInt(profiledata?.contributor_level) > 0 && 
                              hasData(profiledata?.tip_jar_text)) 
                              ? profiledata?.tip_jar_text 
                              : config.tip_jar.text
                            }>
                            
                            <div className=" ">
                              <SvgJsx 
                                    type={'fill'}
                                    icon={'currency-dollar-sm'}
                                    className={` h-5 w-5 group-hover:opacity-80 group-hover:animate-wiggle`}
                                    title={ hasData(profiledata?.tip_jar_text)
                                            ? profiledata?.tip_jar_text 
                                            : config.tip_jar.text}
                                />
                            </div>
                            <div className="hidden xs:block sm:hidden ml-0.5">{config.tip_jar.text}</div>
                            <div className="hidden sm:block ml-0.5">{ hasData(profiledata?.tip_jar_text)
                                            ? profiledata?.tip_jar_text 
                                            : config.tip_jar.text}</div>
                            </div>

                           
                        </div>
                      
                </div>

//non-contributor
if (parseInt(profiledata?.contributor_level) <= 0) {
  return <><Link href={profiledata?.tip_jar_url}>

    {PopupTrigger}

  </Link></>;
}

//contributor
return (
    <div className="menu flex items-center content-center justify-center flex-1 ">

    {!isMounted &&
    <>
      {_tip_jar_json?.items?.length > 1 &&
      <>
        <div
          onClick={()=>{
            window.open(_tip_jar_json?.items[0]?.fieldUrl);
          }}
        >
          {PopupTrigger}
        </div>
      </>
      }
    </>
    }

    {isMounted &&
    <>
      { _tip_jar_json?.items?.length == 1 &&
      <div
        className="w-full"
        onClick={()=>{
          window.open(_tip_jar_json?.items[0]?.fieldUrl);
          
        }}
      >
        {PopupTrigger}
      </div>
      }

      {_tip_jar_json?.items?.length > 1 &&
      <>
      <Popup
        trigger={PopupTrigger}
        position="bottom right"
        //on="click"
        open={open}
        onOpen={()=>set_open(true)}
        //onClose={()=>set_open('')}
        closeOnDocumentClick
        closeOnEscape
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent', zIndex:1000, width: '95%', marginTop: '12px' }}
        arrow={false}
        nested
        //ref={isMounted ? ref : null}
      >
        <div className={`menu ring-0 absolute right-[0px] w-full xs:w-64 `}>
          <div className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2 
                        bg-red-600 "
          >
            
          {_tip_jar_json?.items?.map((e,index) => 
            <Fragment key={index}>
              <PopupMenuOption
                isDisplay={true}
                onClick={()=>{
                  window.open(e?.fieldUrl);
                }}
                icon="currency-dollar-sm"
                title={e?.fieldName}
                colorClass="bg-red-900 hover:bg-red-800 text-white"
              />
            </Fragment>
            )}
            
          </div>
        </div>
      </Popup>
      </>
      }

    </>}
    

  </div>
  );

 
}

export default ProfileMenuTipJarMobile