export default function ProfileFollowChat({
     classColor
    ,onClick
    ,message
    ,shrink
}) {
    return (<>
                 <div className={`flex-1 w-full flex items-center content-center border-b border-gray-700 bg-gray-900`}>
                        
                          <div className={`${classColor} 
                            text-sm 
                            rounded-md shadow cursor-pointer text-center 
                            px-2 py-1 
                             block flex-1 m-1
                            
                            `}
                            onClick={onClick}
                          >
                            {message}
                          </div>
                       
                      </div>
            </>)
}