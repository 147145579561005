
import { padZero } from "@/lib/utils/padZero";
import { useEffect } from "react";
import { useStopwatch } from 'react-timer-hook'



function StopWatch({
     start_date
    ,timerRef
}) {

      //TIMER FUNCTIONS
      const startTime = +new Date(start_date); 
      const now = +new Date(); //now
      const stopwatchOffset = new Date(); stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + (now-startTime)/1000);
      const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
      } = useStopwatch({ autoStart: true,offsetTimestamp: stopwatchOffset});

     
         
  return (
        <div 
            className="whitespace-nowrap flex flex-col text-left"
        >
            <div className=" text-xl font-bolder">
                LIVE
            </div>
            <div className="-mt-1.5 font-mono">
                <span>
                    <div className=" text-xs font-thin">
                    <span>{padZero(hours)}</span>:
                    <span>{padZero(minutes)}</span>:
                    <span>{padZero(seconds)}</span>
                    </div>
                </span>
            </div>
            <div className="hidden" ref={timerRef}>
            {padZero(hours)}:{padZero(minutes)}:{padZero(seconds)}
            </div>
            
        </div>
        )
}

export default StopWatch