
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";

import {useRef} from 'react'


import 'reactjs-popup/dist/index.css';
import PopupMenuOption from "@/components/templateux/popup-menu-option";

export default function ChatMenuChatlist({
    openModalWelcome
   ,openModalAbout
   ,openModalStats
   ,startRaid
   ,saveTimestamp
   ,isAdmin
   ,isOwner
   ,isLive
   ,router
   ,owner_name
   ,show_welcome
   ,show_stats
   ,pause_chat
   ,set_pause_chat
   ,showReminder
   ,closeModal
}) {
  
  const ref = useRef(null);
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {if (ref.current) ref.current.close()};
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};



  return (
    <div className="menu flex items-center content-center  flex-0 w-full px-1 ">
    
      <div className="menu flex flex-1 flex-col gap-y-1 rounded-md shadow-md 
                    bg-gray-800 "
      >


        {/* WELCOME MESSAGE */}
        <PopupMenuOption
          isDisplay={show_welcome}
          onClick={()=>{
            closeMenu();
            openModalWelcome();
          }}
          icon="hand-sm"
          title="Welcome Message"
        />

         {/* ABOUT MESSAGE */}
         <PopupMenuOption
          isDisplay={true}
          onClick={()=>{
            closeMenu();
            openModalAbout();
          }}
          icon="information-circle-sm"
          title="About this host"
        />

         {/* STATS MESSAGE */}
         <PopupMenuOption
          isDisplay={show_stats}
          onClick={()=>{
            closeMenu();
            openModalStats();
          }}
          icon="chart-bar-sm"
          title="Broadcast Stats"
        />

          {/* Raid */}
          <PopupMenuOption
            isDisplay={isLive}
            onClick={(e)=>{
              closeMenu();
              saveTimestamp(e);
            }}
            icon="clock-sm"
            title="Save timestamp"
          />

          {/* Raid */}
          <PopupMenuOption
            isDisplay={isAdmin}
            onClick={(e)=>{
              closeMenu();
              startRaid(e);
            }}
            icon="group-sm"
            title="Start Chat Raid"
          />
      
          {/* Chat Settings*/}
          <PopupMenuOption
            isDisplay={isAdmin}
            onClick={()=>{
              window?.open(`/${owner_name}/settings/chat`);
            }}
            icon="settings-sm"
            title="Edit Chat Settings"
          />

          {/* Chat Settings*/}
          <PopupMenuOption
            isDisplay={true}
            onClick={set_pause_chat}
            icon={pause_chat ? "play-sm" : "pause-sm"}
            title={pause_chat ? "Unpause chat" : "Pause Chat"}
          />
          {/* Chat Settings*/}
          <PopupMenuOption
            isDisplay={true}
            onClick={closeModal}
            icon={"x-sm"}
            title={"Close Chat"}
          />

      </div>
    
  </div>
  );
  
}
