import { hasData } from "./hasData";

export function getInterval(date,timestamp=+new Date) {

    if (!hasData(date)) return -1
    
    const thisdate = +new Date(date)

    // Make a fuzzy time
    var delta = Math.round((+new Date(timestamp) - thisdate) / 1000);

    return delta;
}