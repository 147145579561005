import { hasData } from "@/lib/utils/hasData";
import parsehtml from "@/lib/utils/parsehtml";
import parseMarkDown from "@/lib/utils/parseMarkDown";
import ProfileSection from "./profile-section";
import SubHeader from "./subheader";
import { memo, useContext } from "react";
import { TribeContext } from "../context/tribe-wrapper";


function ProfileDescriptionNested ({
    profiledata
    , isAdmin
}) {

    
  const ssr_data_tribe = useContext(TribeContext)


        return (<>
                <div
                className="w-full"
                >
                 {profiledata?.bol_description == "1" && 
                <ProfileSection>
                    
                    <SubHeader
                        action_url={`/${profiledata?.user_name}/settings/profile?panel=userprofile#description`}
                        action_text={`edit`}
                        action_condition={isAdmin}
                        headertitle={'About'}
                    />
                    
                    <div className={`
                                    text-sm
                                    text-gray-200
                                    markdown
                                    break-words text-ellipsis overflow-hidden
                                    `}>
                        {parsehtml(parseMarkDown(hasData(profiledata?.user_description)
                                ? profiledata?.user_description
                                : 'Nothing to see here'
                                ,ssr_data_tribe?.server_website))} 
                    
                    </div>
                </ProfileSection>
                }

                
                </div>
            </>)
}

export const ProfileDescription = memo(ProfileDescriptionNested)