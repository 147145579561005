import { hasData } from "@/lib/utils/hasData";
import { useEffect, useState } from "react";
import { truncate } from "@/lib/utils/truncate";
import Link from "next/link";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { config } from "@/lib/config";
import { IsJsonValid } from "@/lib/utils/isJsonValid";
import toast, { toastConfig } from "react-simple-toasts";
import BroadcastForm from "./broadcast/broadcast-form";
import { uniqueMembers } from "@/lib/utils/uniqueMembers";
import { timeStrLong } from "@/lib/utils/timeStrLong";
import { timerStr, timerStrDHMS } from "@/lib/utils/timerStr";

export default function ProfileStats({
      ownerdata
    , userdata
    , isAdmin
    , websocket_chatters
    , websocket_listeners
}) {

    //HOOKS ABOVE SECURITY CALLS
    const [showForm, set_showForm] = useState(false); 

    //SUBMISSION STATES
    const [submitting,set_submitting] = useState(false);
    const [changes,set_changes] = useState(false);
    const [form_reset,set_form_reset] = useState(1)
    const [showmore,set_showmore] = useState(false)
    const [guests_json,set_guests_json] = useState([])
    const [active_broadcast,set_active_broadcast] = useState(false)
    const [broadcastcache,set_broadcastcache] = useState(ownerdata?.timestamp)

     //LISTENER DATA
     const { 
        data: broadcastdata
      , loading: broadcastdataisLoading
      , error: broadcastdataisError
                      } = useSWRWrapper(
                        ownerdata?.stream_status?.toString() == "1" ? ownerdata?.broadcast_id : null
                        ,`/api/public/v0/broadcast/stats?id=${ownerdata?.broadcast_id}&cache=${broadcastcache}` //key
                        ,true //retry?
                        ,config.max_retries //max number of retries
                        ,config.retry_interval //retry interval
                        ,config.profile.stats_interval //refreshinterval (0=none)
                      )


    useEffect(()=>{
        if (!broadcastdataisLoading && !broadcastdataisError && broadcastdata) {
                if (IsJsonValid(broadcastdata?.guests_json)) {
                    set_guests_json(JSON.parse(broadcastdata?.guests_json))
                }
        }
       
    }
     ,[
     broadcastdata
    ,broadcastdataisLoading
    ,broadcastdataisError
    ])

    useEffect(()=>{
        set_active_broadcast(ownerdata?.stream_status?.toString()=="1")
    },[ownerdata?.stream_status])

      async function submitHandler(
        submitbody
       ,api_destination
       ,api_method='POST'
       ,api_headers= {
         'Content-Type': 'application/json',
       }
       ,toast_message='Your changes were saved.'
     ) {
      set_submitting(true)
    
    
      try {
        
        //INFO SUBMIT
      
          set_submitting(true)
          const res = await fetch(api_destination, {
            method: api_method,
            headers: api_headers,
            body: JSON.stringify(submitbody),
          })
    
    
          set_changes(false);
          set_submitting(false)
          const json = await res.json()
          if (json) {
    
            
            const { 
                outcome
                , broadcast_id
                , show_name
                , user_name
              } = json[0][0]
              
            if (outcome?.toString()=="1") {
              toastConfig({
                time: 5000,
                className: '',
                position: 'right'
              });
              toast(toast_message, { 
                time: 3000
                , className: ''
                , clickable: true
                , clickClosable: false 
              });
              set_broadcastcache(+new Date())
             
            } else {
              alert(outcome)
            }
            set_showForm(false);
          }
    
          
    
        
      } catch (e) {
       throw Error(e.message)
      }
    }


    if (!active_broadcast) {
        return (<>No active broadcast to show.</>)
    }
    

    return <>

            {!showForm &&
                 <div className="px-5 pt-5 w-full">

                    <div className="flex items-center content-center">
            
                        

                        <h3 className="text-xl font-bold mt-2 flex-0">
                            Broadcast Info 
                        </h3>
                        {isAdmin &&
                        <div
                         className="flex-0 mt-2 ml-3 border px-1 py-0.5 text-2xs cursor-pointer rounded-md
                                    text-gray-700 hover:text-gray-200 border-gray-700 hover:border-gray-200 
                                        "
                         onClick={()=> {
                            set_showForm(true);
                         }} 
                        >
                            edit
                        </div>
                        }
                    </div>

                    <div className={`
                                            text-sm w-full mb-5
                                            text-gray-200
                                            
                                            `}>
                        <ul>
                            
                            <li className="font-bold text-md"
                                
                            >
                                <div 
                                    
                                >
                                {hasData(broadcastdata?.broadcast_title) 
                                    ? broadcastdata?.broadcast_title 
                                    : 'Untitled Show'}
                                </div>
                            </li>
                            
                            {hasData(broadcastdata?.broadcast_description) &&
                            
                            <li>
                                <div
                                    className={`text-sm italic  ${showmore ? 'hidden' : 'block'}`}
                                    
                                >
                                    {truncate(broadcastdata?.broadcast_description,64)}
                                </div>
                                <div 
                                    className={`text-sm italic  ${!showmore ? 'hidden' : 'block'}`}
                                   
                                 >   
                                    {broadcastdata?.broadcast_description}
                                </div>
                                
                                <div
                                    onClick={()=>set_showmore(!showmore)}
                                    className={`underline hover:no-underline text-sm  ${broadcastdata?.broadcast_description?.length > 64 ? 'block cursor-pointer' : 'hidden'}`}
                                >
                                    see {showmore ? 'less' : 'more'}
                                </div>
                            </li>
                            }
                            
                            <li className="mt-4">
                                <h3 className="text-xl font-bold mt-2">
                                Featuring
                                </h3>
                            </li>
                            <li >
                                
                                <ul>
                                    <li
                                     className="list-disc ml-4"
                                     
                                   > 
                                    <Link
                                        href={`/${broadcastdata?.owner_name?.toLowerCase()}`}
                                        className='underline hover:no-underline mr-1'>

                                        {broadcastdata?.owner_name}

                                    </Link>
                                    (host)
                                    
                                       
                                   </li> 
                                   {guests_json.map((guest,index)=> {
                                    return (
                                        <li
                                            className="list-disc ml-4"
                                            key={index}
                                            >
                                                <Link
                                                    href={`/${guest?.name?.toLowerCase()}`}
                                                    className='underline hover:no-underline mr-1'>

                                                    {guest?.name}

                                                </Link>
                                                ({guest.type})
                                            </li>
                                    );
                                   })}
                                   
                                   
                               </ul>
                            </li>
                            <li>
                                <div className="table w-full rounded-md border border-gray-700 p-2 mt-5  ">
                                    <div className="table-header-group  border border-gray-600">
                                        <div className="table-row text-white bg-gray-950 border border-gray-200 font-bold text-sm sm:text-xl">
                                            <div className="table-cell   p-2">
                                                Stats
                                            </div>
                                            <div className="table-cell   p-2 text-center w-24 sm:w-32 ">
                                                Listeners
                                            </div>
                                            <div className="table-cell   text-center p-2 w-24 sm:w-32">
                                                Chatters
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group bg-gray-700 hover:bg-gray-800 font-bold">
                                        <div className="table-row">
                                            <div className="table-cell   p-2">
                                            Current
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {websocket_listeners?.length || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {websocket_chatters?.length || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell    pl-5 text-left p-2">
                                            Guests
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {websocket_listeners?.length-uniqueMembers(websocket_listeners)?.length || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {websocket_chatters?.length-uniqueMembers(websocket_chatters)?.length || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell   pl-5 text-left p-2">
                                            Members
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {uniqueMembers(websocket_listeners)?.length || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {uniqueMembers(websocket_chatters)?.length || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group bg-gray-700 hover:bg-gray-800  font-bold">
                                        <div className="table-row">
                                            <div className="table-cell   p-2">
                                            Peak
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.peak_listeners || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.peak_chatters || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell  pl-5 text-left p-2">
                                            Guests
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {(broadcastdata?.peak_listeners_guests) || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {(broadcastdata?.peak_chatters_guests) || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell   pl-5 text-left p-2">
                                            Members
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.peak_listeners_members || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.peak_chatters_members || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group bg-gray-700 hover:bg-gray-800  font-bold">
                                        <div className="table-row">
                                            <div className="table-cell  p-2">
                                            Total
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.sum_listeners || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.sum_chatters || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell  pl-5 text-left p-2">
                                            Guests
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {(broadcastdata?.sum_listeners_guests) || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {(broadcastdata?.sum_chatters_guests) || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell   pl-5 text-left p-2">
                                            Members
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.sum_listeners_members || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.sum_chatters_members || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell  pl-5 text-left p-2">
                                            Followers
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.sum_listeners_followers || 0}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {broadcastdata?.sum_chatters_followers || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group bg-gray-700 hover:bg-gray-800  font-bold">
                                        <div className="table-row">
                                            <div className="table-cell  p-2">
                                            Time Spent
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {timerStrDHMS(broadcastdata?.total_listen_time || 0,true)}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {timerStrDHMS(broadcastdata?.total_chat_time || 0,true)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell   pl-5 text-left p-2">
                                            Guests
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {timerStrDHMS(broadcastdata?.total_listen_time_guests || 0,true)}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {timerStrDHMS(broadcastdata?.total_chat_time_guests || 0,true)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell  pl-5 text-left p-2">
                                            Members
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {timerStrDHMS(broadcastdata?.total_listen_time_members || 0,true)}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {timerStrDHMS(broadcastdata?.total_chat_time_members || 0,true)}
                                            </div>
                                        </div>
                                    </div><div className="table-row-group group  hover:bg-gray-800">
                                        <div className="table-row">
                                            <div className="table-cell   pl-5 text-left p-2">
                                            Followers
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {timerStrDHMS(broadcastdata?.total_listen_time_followers || 0,true)}
                                            </div>
                                            <div className="table-cell   text-center p-2">
                                            {timerStrDHMS(broadcastdata?.total_chat_time_followers || 0,true)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-row-group group bg-gray-700 hover:bg-gray-800  font-bold">
                                <div className="table-row">
                                    <div className="table-cell  p-2">
                                    Misc Stats
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:bg-gray-800">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Messages Sent
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {broadcastdata?.sum_chats || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:bg-gray-800">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Emojis Sent
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {broadcastdata?.sum_emojis || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:bg-gray-800">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Gifs Sent
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {broadcastdata?.sum_gifs || 0}
                                    </div>
                                </div>
                            </div>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                    
                </div>
            }

            {showForm &&
                <BroadcastForm
                    submitHandler={submitHandler}
                    submitting={submitting}
                    set_submitting={set_submitting}
                    set_changes={set_changes}
                    changes={changes}

                    //schedule info
                    broadcast_id={broadcastdata?.broadcast_id}
                    itemdata={broadcastdata}
                    itemdataisLoading={false}
                    itemdataisError={false}
                    defaults={{title:'',description:''}}
                    defaultsisLoading={false}
                    defaultsisError={false}

                    ownerdata={ownerdata}
                    userdata={userdata}

                    show_graphic={false}
                    form_reset={form_reset}
                    set_form_reset={set_form_reset}
                    actionText={'Save Changes'}
                    broadcastcache={broadcastcache}
                    set_broadcastcache={set_broadcastcache}
                />
            }        

    </>;
}