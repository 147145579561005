
import * as React from "react";
import SettingsSave from "@/components/settings/settings-save";
import { useContext, useEffect, useState } from "react";
import FormInput from "../../templateux/form-input/form-input";
import { hasData } from "@/lib/utils/hasData";
import WizardSection from "@/components/templateux/wizard/wizard-section";
import WizardHeader from "@/components/templateux/wizard/wizard-header";
import SortableFormUsers from "@/components/templateux/sortable-form/users/sortable-form-users";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import isAdminUser from "@/lib/utils/isAdminUser";
import { TribeContext } from "@/components/context/tribe-wrapper";
import { tablehelptype } from "@/lib/config";

type Props = {
  //submit
  submitHandler?: any;
  submitting?: any;
  set_submitting?: any;
  set_changes?: any;
  changes?: any;

  //broadcast info
  broadcast_id?: any;
  itemdata?: any;
  itemdataisLoading?: any;
  itemdataisError?: any;
  defaults?: any;
  defaultsisLoading?: any;
  defaultsisError?: any;
  show_graphic?: any;
  form_reset?: any;
  set_form_reset?: any;
  actionText?:any;
  broadcastcache?:any;
  set_broadcastcache?:any;

  //inherited info
  ownerdata?: any;
  userdata?: any;
  navtype?: any;
  helptype?: any;
}

function BroadcastForm({
  //submitting
   submitHandler
  ,submitting
  ,set_submitting
  ,set_changes
  ,changes

  //broadcast info
  ,broadcast_id
  ,itemdata
  ,itemdataisLoading
  ,itemdataisError
  ,defaults
  ,defaultsisLoading
  ,defaultsisError
  ,show_graphic
  ,form_reset
  ,set_form_reset
  ,actionText
  ,broadcastcache
  ,set_broadcastcache

  //inherited info
  ,ownerdata
  ,userdata
  ,navtype
  ,helptype
}: Props) {

  // console.log("itemdata",itemdata)

  const ssr_data_tribe = useContext(TribeContext)

 
  const [admin_user_name,set_admin_user_name] = useState((!itemdataisLoading && !itemdataisError && itemdata) 
                                                          ? itemdata?.user_name 
                                                          : userdata?.user_name)
  const [bol_admin_user_name,set_bol_admin_user_name] = useState("0")
  const [user_id,set_user_id] = useState(userdata?.id)

  //TIMEZONE DATA

  const [wizard_id,set_wizard_id] = useState(0);
  const wizard_array = [
    {
      title:'Show info'
      ,description:''
      ,short: 'Info'
    }
  ]


  const [_broadcast_title, set_broadcast_title] = useState(hasData(itemdata?.broadcast_title) ? itemdata?.broadcast_title : '')
  const [_broadcast_description, set_broadcast_description] = useState(hasData(itemdata?.broadcast_description) ? itemdata?.broadcast_description : '')
  const [_broadcast_thumbnail, set_broadcast_thumbnail] = useState(hasData(itemdata?.broadcast_thumbnail) ? itemdata?.broadcast_thumbnail : '')
  const [_guests_json,set_guests_json] = useState(hasData(itemdata?.guests_json) ? JSON.parse(itemdata?.guests_json) : [])
  const [_broadcast_category,set_broadcast_category] = useState(hasData(itemdata?.broadcast_category) ? itemdata?.broadcast_category : ssr_data_tribe?.marketing_seo_category)
  const [_broadcast_tags,set_broadcast_tags] = useState(hasData(itemdata?.broadcast_tags) ? itemdata?.broadcast_tags : '')

  const [isAdmin,set_isAdmin] = useState(isAdminUser(
                                             ownerdata?.id?.toString()          //user_id
                                            ,ownerdata?.bol_staff_help    //bol_staff_help
                                            ,ownerdata?.staff_json        //staff_json
                                            ,ownerdata?.bol_admin_help    //bol_admin_help
                                            ,userdata?.id?.toString()                            //my_id
                                            ,userdata?.bol_admin?.toString()              //global_admin
                                            ,tablehelptype(helptype)
                                        )
  )
  
  useEffect(()=>{


      if (form_reset==1 
        && ((
            hasData(broadcast_id) 
            && broadcast_id.toString() !== "0" 
            && !itemdataisLoading && !itemdataisError
            )
          || 
            (
            broadcast_id?.toString() == "0" 
            && !defaultsisLoading && !defaultsisError
            ))
        ) {


          set_broadcast_title(hasData(itemdata?.broadcast_title)
                                      ? itemdata?.broadcast_title 
                                      : hasData(defaults?.title) 
                                        ? defaults?.title 
                                        : ''
                                    )
          set_broadcast_description(hasData(itemdata?.broadcast_description) 
                                      ? itemdata?.broadcast_description 
                                      : hasData(defaults?.description) 
                                        ? defaults?.description 
                                        : ''
                                      )
          set_broadcast_thumbnail(hasData(itemdata?.broadcast_thumbnail) 
                                      ? itemdata?.broadcast_thumbnail 
                                      : hasData(defaults?.image) 
                                        ? defaults?.image 
                                        : ''
                                      )
          set_guests_json(hasData(itemdata?.guests_json) 
                                      ? JSON.parse(itemdata?.guests_json) 
                                      : hasData(defaults?.guests) 
                                        ? (defaults?.guests) 
                                        : []
                                      ) 

          set_form_reset(0) //make sure we dont remove the data again now that it's filled in.
      
      }
   
  },[
     itemdata
    ,itemdataisLoading
    ,itemdataisError
    ,defaults
    ,defaultsisLoading
    ,defaultsisError
    ,broadcast_id
    ,changes
    ,form_reset
    ,set_form_reset
  ])

  function handleSubmit(e) {

    e.preventDefault();

      //final cleanup on host json
      var guests_json_clean = (Array.isArray(_guests_json)) ? _guests_json : JSON.parse(_guests_json)

      if (guests_json_clean?.length > 0) {
        guests_json_clean = [..._guests_json];
        guests_json_clean?.map((e,i) => {
          if (guests_json_clean[i][`open`] == 1) guests_json_clean[i][`open`] = 0;
        })
      }

      const post_data = {
          broadcast_title: _broadcast_title
        , broadcast_description: _broadcast_description
        , broadcast_thumbnail: _broadcast_thumbnail
        , broadcast_category: _broadcast_category
        , broadcast_tags: _broadcast_tags
        , guests_json: JSON.stringify(guests_json_clean)
        , admin_help_user_id: user_id
        , set_submitting
      } 


      submitHandler(post_data
      ,`/api/private/broadcast/update?id=${broadcast_id}`
      ,'POST'
      ,{
        'Content-Type': 'application/json',
      }
      ,'Your changes were saved'
    )
    set_changes(false);
    let cachedate = + new Date;
    set_broadcastcache(cachedate?.toString())
    
  }
  
  


  return (
    <>
    {/* info COMPONENT */}
    <div className="w-full">

      {/* DISABLING THIS SINCE WE ONLY USE ONE SECTION */}
      {/* <WizardHeader
        wizard_array={wizard_array}
        wizard_id={wizard_id}
        set_wizard_id={set_wizard_id}
      /> */}
    

      {/* FORM */}
      <div>
      <form 
        className="space-y-6" 
        onSubmit={handleSubmit}
      >

        <WizardSection
          section_id={0}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
        >
        
  
          <FormInput type={'text'}
            title={'Broadcast Title'}
            description={''}
            helptip={''}
            
            name={'broadcast_title'}
            placeholder={""}
            
            value={_broadcast_title}
            set_value={set_broadcast_title}

            //submitting
            submitting={submitting}
            set_submitting={set_submitting}
            set_changes={set_changes}
            changes={changes}
          /> 

          <FormInput type={'textarea-autosize'}
            title={'Broadcast Description'}
            description={''}
            helptip={''}

            
            name={'broadcast_description'}
            placeholder={""}
            
            value={_broadcast_description}
            set_value={set_broadcast_description}

            //submitting
            submitting={submitting}
            set_submitting={set_submitting}
            set_changes={set_changes}
            changes={changes}
          /> 

          <FormInput type={'text'}
            title={'Broadcast Tags'}
            description={''}
            helptip={''}

            
            name={'broadcast_tags'}
            placeholder={""}
            
            value={_broadcast_tags}
            set_value={set_broadcast_tags}

            //submitting
            submitting={submitting}
            set_submitting={set_submitting}
            set_changes={set_changes}
            changes={changes}
          /> 

            {show_graphic &&
            <FormInput type={'image'}
              title={'Banner Graphic'}
              description={'Pick an image 128 pixels tall'}
              helptip={''}
              
              name={'broadcast_thumbnail'}
              placeholder={"http://"}
              
              value={_broadcast_thumbnail}
              set_value={set_broadcast_thumbnail}

              //submitting
              submitting={submitting}
              set_submitting={set_submitting}
              set_changes={set_changes}
              changes={changes}
            /> 
            }

              <div className="my-4">

              <h3 className="font-bold mt-4 mb-2">
                Featuring
              </h3>

                <div 

                    className={`rounded-lg flex flex-item mx-1 overflow-hidden border 
                 
                          bg-blue-900 border-blue-700
                            ${ isAdmin && bol_admin_user_name=="0"
                               ? 'cursor-pointer hover:bg-blue-800'
                               : ''}
                    `}
                >
                  <div 
                    className="flex-1 flex items-center content-center"
                  >

             
              
             
              

            

                  
                    <div 
                        className={`flex-1  flex items-center content-center 
                                    text-white 
                                    
                                    `}
                    > 
                        
                        
                      

                      {/* STATIC */}
                      
                      
                      <div
                          className="flex-0 px-2 py-0.5 mx-2 w-16 text-center  text-3xs xs:text-2xs sm:text-xs  rounded-md text-white bg-blue-700 font-bold" 
                      >
                        owner
                      </div>

                      {/* STATIC */}
                      {bol_admin_user_name=="0" &&
                      <div 
                        className='flex-1 font-bold text-xs xs:text-sm sm:text-xl  flex items-center content-center'
                        onClick={()=> {
                          if (isAdmin) set_bol_admin_user_name("1");
                        }}
                      >
                        <div className="flex-1 my-2">
                        {admin_user_name}
                        </div>
                        {isAdmin &&
                        <div className="flex-0">
                          <div 
                            onClick={()=>set_bol_admin_user_name("1")}
                            className="cursor-pointer text-white bg-blue-600 hover:bg-blue-500 font-bold px-4 py-2 rounded-r-md
                                      hover:opacity-75
                                      w-20 text-center
                                      "
                          >
                          Edit
                          </div>
                        </div>
                        }
                      </div>

                      
                      }

                      {/* EDITING */}
                      {bol_admin_user_name=="1" &&
                      <div className='flex-1 font-bold text-xl flex items-center content-center'>
                        <div className="flex-1">
                        <FormInput type={'user'}
                          title={'Owner'}
                          description={''}
                          helptip={''}
                          
                          name={'admin_user_name'}
                          placeholder={"Username"}
                          
                          value={admin_user_name}
                          set_value={set_admin_user_name}
                          hidden_value={user_id}
                          set_hidden_value={set_user_id}
          
                          //submitting
                          submitting={submitting}
                          set_submitting={set_submitting}
                          set_changes={set_changes}
                          changes={changes}
                        /> 
                        </div>
                        <div className="flex-0 ">
                          <div 
                            onClick={()=>set_bol_admin_user_name("0")}
                            className="cursor-pointer text-white bg-blue-600 hover:bg-blue-500 font-bold px-4 py-2 rounded-r-md
                                      hover:opacity-75
                                      w-20 text-center
                                      "
                          >
                            Set
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  

                  </div>
                </div>
              </div>




         {/* guests_json */}
         <div className="my-4">
          {/* <HelpTip 
                id="guests_json" 
                tip={help["guests_json"]} 
              /> */}
            {/* <label htmlFor="guests_json">
              
              <h3 className="font-bold">Hosts</h3>
            </label> */}
            
            <div>
              <SortableFormUsers
                type='guest'
                addbutton='Add more folks'
                inputJSON={_guests_json}
                set_inputJSON={set_guests_json}
                unique_id={'guests_json'}
                owner_name={ownerdata?.user_name}
                set_changes={set_changes}
                timestamp={itemdata?.timestamp}
                form_type="show"
              />
            </div>

        </div>

    </WizardSection>  
 

   

    <div className="flex items-center content-center">
      <div className="flex-1 flex items-center content-center">

        {wizard_id > 0 &&
        <div className='flex-0 mr-2'>
          <div
              className="inline-flex justify-center p-2.5  font-medium text-white bg-gray-500 hover:bg-gray-400 cursor-pointer border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
              onClick={()=> {
                set_wizard_id(wizard_id-1)
              }}
          >
            <span className='flex'>
            <SvgJsx 
                type={'fill'}
                icon={'chevron-left-sm'}
                className={` h-5 w-5`}
                title={'Previous'}
            />
            </span>
          </div>
        </div>
        }

        {wizard_id < wizard_array.length-1 &&
        <div className="flex-0">
          <div
              className="inline-flex justify-center p-2 font-medium cursor-pointer border border-transparent rounded-md focus:outline-none
                      text-white bg-gray-500 hover:bg-gray-400 
              
              "
              onClick={()=> {
                set_wizard_id(wizard_id+1)
              }}
          >
            <span className='block'>
              <div className="flex items-center content-center">

                <div className="flex-0">
                  {wizard_array[wizard_id+1].short}
                </div>
                <div className="flex-0">
                  <SvgJsx 
                      type={'fill'}
                          
                      icon={'chevron-right-sm'}
                      className={` h-5 w-5`}
                      title={'Next'}
                  />
                </div>
              </div>
             
            </span>
          </div>
        </div>
        }

      </div>
      <div className="flex-0">
        {/* SUBMIT BUTTON */}
          <SettingsSave
              submitting_state={submitting}
              changes={changes}
              actionText={actionText}
              actionTextShort={actionText}
              className={` whitespace-nowrap text-xl inline-flex items-center justify-center px-4 py-2 border border-red-500 rounded-lg shadow-md  font-medium text-white bg-red-500 hover:bg-red-600 bg-gradient-to-b from-red-600  to-red-500 hover:from-red-700 hover:to-red-500 text-center flex-0`}
            />
      </div>
    </div>

        
        
      </form>
    </div>
    {/* SET referral FORM */}

    </div>
    {/* // referral SET COMPONENT */}
  </>
  )
}

export default BroadcastForm



